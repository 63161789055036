import Header from "../Partials/Header";
import LatestList from "../Components/LastestList";
import { MdManageAccounts } from "react-icons/md";
import { IoAddOutline } from "react-icons/io5";
import {
  AiFillSetting,
  AiOutlineLogout,
  AiOutlineUnorderedList,
} from "react-icons/ai";
import { BiStats, BiCategory } from "react-icons/bi";
import { FaUserPlus, FaUserFriends } from "react-icons/fa";
import { Link } from "react-router-dom";
import { getTop } from "../Utils/APIFetching";
import { useEffect, useState, useContext } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { AuthContext } from "../Utils/AuthContext";

function Dashboard() {
  const { userInfo, setUser, setUserInfo } = useContext(AuthContext);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getTop()
      .then((res) => {
        return res;
      })
      .then((res) => {
        setData(res.data);

        setLoading(false);
      });
  }, []);

  function handleLogout() {
    localStorage.removeItem("token");
    setUser(false);
    setUserInfo(false);
  }
  return (
    <div>
      <Header />
      <div className="p-5 flex flex-wrap">
        <div className="lg:px-5 w-full lg:w-[420px] ">
          <LatestList factories={data.factories} loading={loading} />
        </div>
        <div className="grow">
          <div className="grid mt-8 lg:mt-0 lg:grid-cols-3 gap-4">
            <Link
              to="/add"
              className="dashboard-btn-active items-stretch p-4 rounded-md min-h-[150px] "
            >
              <h1 className="items-start font-black text-xl ">ADD FACTORY</h1>
              <div className="flex justify-center mt-6">
                <IoAddOutline size={95} className="text-green-600" />
              </div>
            </Link>

            <Link
              to="/list"
              className="bg-white p-4 rounded-md min-h-[150px] drop-shadow-md"
            >
              <h1 className="font-black text-xl ">VIEW LIST</h1>
              <div className="flex justify-center items-center">
                <div className="flex justify-center items-center mt-6 ">
                  <h2 className="text-7xl mr-2">
                    {loading ? (
                      <ClipLoader
                        color={"blue"}
                        loading={loading}
                        size={50}
                        aria-label="Loading Spinner"
                      />
                    ) : (
                      data.total
                    )}
                  </h2>
                  <h2>
                    Factories
                    <br /> Listed
                  </h2>
                </div>
              </div>
            </Link>
            <Link
              to={userInfo.role === "Admin" && "/report"}
              className={
                userInfo.role === "Admin"
                  ? "dashboard-btn-active items-stretch p-4 rounded-md min-h-[150px] "
                  : "dashboard-btn-disable items-stretch p-4 rounded-md min-h-[150px] "
              }
            >
              <h1 className="items-start font-black text-xl">REPORT</h1>
              <div className="flex justify-center mt-6">
                <BiStats size={95} className="text-blue-600" />
              </div>
            </Link>
          </div>
          <div className="grid lg:grid-cols-2 grid-rows-2 gap-4 mt-5">
            <div className="row-span-2 ">
              <div className="grid row-span-2 gap-4">
                <Link
                  to={userInfo.role === "Admin" && "/category"}
                  className={
                    userInfo.role === "Admin"
                      ? "dashboard-btn-active flex justify-between p-4 rounded-md min-h-[100px]"
                      : "dashboard-btn-disable flex justify-between p-4 rounded-md min-h-[100px]"
                  }
                >
                  <h1 className=" font-black text-md ">Categories</h1>
                  <BiCategory size={60} className="text-gray-300 " />
                </Link>
                <Link
                  to={userInfo.role === "Admin" && "/logs"}
                  className={
                    userInfo.role === "Admin"
                      ? "dashboard-btn-active flex justify-between p-4 rounded-md min-h-[100px]"
                      : "dashboard-btn-disable flex justify-between p-4 rounded-md min-h-[100px]"
                  }
                >
                  <h1 className=" font-black text-md ">User Logs</h1>
                  <AiOutlineUnorderedList size={60} className="text-gray-300" />
                </Link>
              </div>
            </div>
            <div className="row-span-2 ">
              <div className="grid row-span-2 gap-4">
                <div className="grid grid-cols-2 gap-4">
                  <Link
                    to={userInfo.role === "Admin" && "/users"}
                    className={
                      userInfo.role === "Admin"
                        ? "dashboard-btn-active flex justify-between p-4 rounded-md min-h-[100px]"
                        : "dashboard-btn-disable flex justify-between p-4 rounded-md min-h-[100px]"
                    }
                  >
                    <h1 className=" font-black text-md ">Accounts</h1>
                    <FaUserFriends size={60} className="text-gray-300" />
                  </Link>
                  <Link
                    to={userInfo.role === "Admin" && "/user/create"}
                    className={
                      userInfo.role === "Admin"
                        ? "dashboard-btn-active flex justify-between p-4 rounded-md min-h-[100px]"
                        : "dashboard-btn-disable flex justify-between p-4 rounded-md min-h-[100px]"
                    }
                  >
                    <h1 className=" font-black text-md ">Create</h1>
                    <FaUserPlus size={60} className="text-gray-300" />
                  </Link>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <Link
                    to="/settings"
                    className="flex justify-center items-center bg-white p-4 rounded-md min-h-[100px] drop-shadow-md hover:cursor-pointer"
                  >
                    <AiFillSetting size={60} className="text-black" />
                  </Link>
                  <div
                    className="flex justify-center items-center bg-white p-4 rounded-md min-h-[100px] drop-shadow-md hover:cursor-pointer"
                    onClick={handleLogout}
                  >
                    <AiOutlineLogout size={60} className="text-red-500" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
