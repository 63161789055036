import Header from "../Partials/Header";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { useState } from "react";
import { createUser } from "../Utils/APIFetching";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

function UserForm() {
  const [inputs, setInputs] = useState({});
  const navigate = useNavigate();
  const handleOnSubmit = async (e) => {
    e.preventDefault();

    toast
      .promise(createUser(inputs), {
        loading: "Creating User...",
        success: (res) => res.data.message,
        error: (err) => err.response.data.message,
      })
      .then(() => navigate("/users"));
  };
  return (
    <div>
      <Header />
      <div className=" py-8 flex justify-center">
        <div className="lg:w-1/2 bg-white rounded-md p-5">
          <div>
            <h1 className="text-xl">Create new user</h1>
            <p className="text-sm py-2">
              Create a user and choose a password for him as <br />
              he can change password later.
            </p>
          </div>
          <form className="mt-5" autoComplete="off" onSubmit={handleOnSubmit}>
            <div className="flex space-x-5">
              <TextField
                id="standard-basic"
                label="Full Name"
                variant="standard"
                className="grow"
                onChange={(e) => setInputs({ ...inputs, name: e.target.value })}
              />
              <FormControl variant="standard" className="grow">
                <InputLabel id="demo-simple-select-standard-label">
                  Role
                </InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  // value={age}
                  onChange={(e) =>
                    setInputs({ ...inputs, role: e.target.value })
                  }
                  label="Raw Material Origin"
                >
                  <MenuItem value="User">User</MenuItem>
                  <MenuItem value="Licenser">Licenser</MenuItem>
                  <MenuItem value="Admin">Admin</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="flex space-x-5">
              <TextField
                id="standard-basic"
                label="User Email"
                variant="standard"
                className="grow"
                onChange={(e) =>
                  setInputs({ ...inputs, email: e.target.value })
                }
              />
            </div>
            <div className="flex space-x-5">
              <TextField
                id="standard-basic"
                label="Department"
                variant="standard"
                className="grow"
                onChange={(e) =>
                  setInputs({ ...inputs, department: e.target.value })
                }
              />
              <TextField
                id="standard-basic"
                label="Password"
                type="password"
                variant="standard"
                className="grow"
                onChange={(e) =>
                  setInputs({ ...inputs, password: e.target.value })
                }
              />
            </div>
            <div className="py-5">
              <button className="bg-black p-3 text-white" type="submit">
                Create User
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default UserForm;
