import Header from "../Partials/Header";
import ClipLoader from "react-spinners/ClipLoader";

function Loading() {
  return (
    <div>
      <Header />
      <div className="pt-52 flex justify-center items-center">
        <ClipLoader
          className="text-red-600 "
          loading={true}
          size={150}
          aria-label="Loading Spinner"
        />
      </div>
    </div>
  );
}

export default Loading;
