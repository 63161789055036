import { BrowserRouter, Routes, Route } from "react-router-dom";

import LoginPage from "./Pages/Login";
import Dashboard from "./Pages/Dashboard";
import Form from "./Pages/Form";
import Edit from "./Pages/Edit";
import List from "./Pages/List";
import Category from "./Pages/Category";
import Logs from "./Pages/Logs";
import Details from "./Pages/Details";
import Report from "./Pages/Report";
import Users from "./Pages/Users";
import UserForm from "./Pages/UserForm";
import Loading from "./Components/Loading";
import License from "./Pages/License";
import Settings from "./Pages/Settings";
import Protect from "./Protect";
import NotFound from "./Pages/NotFound";
import { Toaster } from "react-hot-toast";
import "react-loading-skeleton/dist/skeleton.css";

import { useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import { AuthContext } from "./Utils/AuthContext";

function App() {
  const [user, setUser] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token == null) {
      setUser(false);
      setLoading(false);
      return;
    }

    var decoded = jwt_decode(token);
    var dateNow = new Date();
    if (decoded.exp * 1000 < dateNow.getTime()) {
      localStorage.removeItem("token");
      setUser(false);
      setLoading(false);
      return;
    }
    setUserInfo(decoded);
    setUser(true);
    setLoading(false);
  }, []);

  if (loading) return <h1>Loading..</h1>;
  return (
    <div className="min-h-screen bg-gray-50">
      <AuthContext.Provider value={{ user, setUser, userInfo, setUserInfo }}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="/" element={<Protect />}>
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="list" element={<List />} />
              <Route path="add" element={<Form />} />
              <Route path="edit/:slug" element={<Edit />} />
              <Route path="category" element={<Category />} />
              <Route path="details/:slug" element={<Details />} />
              <Route path="loading" element={<Loading />} />
              <Route path="license/:slug" element={<License />} />
              <Route path="settings" element={<Settings />} />
              {/* {userInfo.role === "Admin" && ( */}
              {/* <> */}
              <Route path="report" element={<Report />} />
              <Route path="users" element={<Users />} />
              <Route path="user/create" element={<UserForm />} />
              {/* </> */}
              {/* )} */}
              {userInfo.role === "Admin" && (
                <Route path="logs" element={<Logs />} />
              )}

              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </AuthContext.Provider>
      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: "",
          duration: 5000,
          style: {
            background: "#363636",
            color: "#fff",
          },

          // Default options for specific types
          success: {
            duration: 3000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />
    </div>
  );
}

export default App;
