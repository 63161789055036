import Header from "../Partials/Header";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ReactPaginate from "react-paginate";

import { MdDone, MdRemoveRedEye, MdOutlineClose } from "react-icons/md";

import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { getFactories } from "../Utils/APIFetching";
import Loading from "../Components/Loading";

function List() {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    getFactories(currentPage)
      .then((res) => {
        return res;
      })
      .then((res) => {
        setTotalPages(res.data.totalPages);
        setCurrentPage(res.data.currentPage);
        setList(res.data.factories);
        setLoading(false);
      });
  }, [currentPage]);

  function handlePageClick(e) {
    setCurrentPage(e.selected + 1);
  }

  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      <Header />
      <div className="p-5 flex justify-center">
        <div>
          <TableContainer component={Paper}>
            <Table
              className="lg:w-[720px]"
              // sx={{ minWidth: 750 }}
              aria-label="simple table"
            >
              <TableHead className="bg-gray-100">
                <TableRow>
                  <TableCell>Factory Name</TableCell>
                  <TableCell align="right">Sector</TableCell>
                  <TableCell align="right">Permit</TableCell>
                  <TableCell align="right">View</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <Data />
              </TableBody>
            </Table>
          </TableContainer>
          {totalPages > 1 && (
            <ReactPaginate
              breakLabel="..."
              nextLabel="next"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={totalPages}
              previousLabel="previous"
              renderOnZeroPageCount={null}
              activeClassName="font-black"
              className="flex justify-center items-center space-x-2 my-4"
              pageClassName="bg-black h-8 w-8 flex justify-center items-center pointer-cursor"
              pageLinkClassName="text-white"
              previousClassName="bg-black h-8 px-3 flex justify-center items-center text-white pointer-cursor"
              nextClassName="bg-black h-8 px-3 flex justify-center items-center text-white pointer-cursor"
            />
          )}
        </div>
      </div>
    </div>
  );

  function Data() {
    return (
      <>
        {list.map((factory) => (
          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell component="th" scope="row">
              {factory.name}
            </TableCell>
            <TableCell align="right">{factory.type}</TableCell>
            <TableCell>
              <div className="flex justify-end">
                {factory.license ? (
                  <MdDone size={20} className="text-green-500" />
                ) : (
                  <MdOutlineClose size={20} className="text-red-500" />
                )}
              </div>
            </TableCell>
            <TableCell align="right">
              <Link
                to={`/details/${factory.slug}`}
                className="flex justify-end"
              >
                <MdRemoveRedEye />
              </Link>
            </TableCell>
          </TableRow>
        ))}
      </>
    );
  }
}

export default List;
