import Header from "../Partials/Header";
import Loading from "../Components/Loading";
import { useState, useEffect, useRef, useContext } from "react";
import { getFactory, renewLicense } from "../Utils/APIFetching";
import { useParams } from "react-router-dom";
import { HiOutlinePrinter } from "react-icons/hi";
import { MdAutorenew } from "react-icons/md";
import { ComponentToPrint } from "./Print";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-hot-toast";
import { AuthContext } from "../Utils/AuthContext";
function License() {
  const { userInfo } = useContext(AuthContext);
  const { slug } = useParams();
  const [loading, setLoading] = useState(true);
  const [factory, setFactory] = useState({});

  useEffect(() => {
    getFactoryDetails();
  }, []);

  function getFactoryDetails() {
    getFactory(slug)
      .then((res) => {
        return res;
      })
      .then((res) => {
        setFactory(res.data.factory);
        setLoading(false);
      });
  }
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  function handleRenewLicense() {
    toast.promise(renewLicense(factory.slug), {
      loading: "Renewing License",
      success: (res) => res.data.message,
      error: (err) => err.response.data.message,
    });
  }
  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      <Header />

      <div className="m-10  z-0">
        <div className="flex justify-end my-2 space-x-2">
          {new Date().toISOString() > factory.license.expireAt && (
            <>
              {(userInfo.role === "Admin" || userInfo.role === "Licenser") && (
                <button
                  className="bg-red-500 py-2 px-3 text-white flex items-center"
                  onClick={handleRenewLicense}
                >
                  <MdAutorenew size={20} className="mr-1" />
                  Renew License
                </button>
              )}
            </>
          )}
          <button
            className="bg-blue-500 py-2 px-3 text-white flex items-center"
            onClick={handlePrint}
          >
            <HiOutlinePrinter size={20} className="mr-1" />
            Print
          </button>
        </div>
        <ComponentToPrint ref={componentRef} factory={factory} />
      </div>
    </div>
  );
}

export default License;
