import Header from "../Partials/Header";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { BiTrash } from "react-icons/bi";
import { MdGppGood } from "react-icons/md";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

import { useEffect, useState } from "react";
import { getUsers, activateUser } from "../Utils/APIFetching";
import { toast } from "react-hot-toast";

function Users() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getUsers()
      .then((res) => {
        return res;
      })
      .then((res) => {
        setUsers(res.data.users);
        setLoading(false);
      });
  }, [users]);

  function activate(id) {
    toast.promise(activateUser(id), {
      loading: "Checking...",
      success: (res) => res.data.message,
      error: (err) => err.response.data.message,
    });
  }
  return (
    <div>
      <Header />
      <div className="p-5 flex justify-center">
        <div>
          <div className="py-3 flex justify-end">
            <Link to="/user/create">
              <button className="bg-black p-2 text-white">Create User</button>
            </Link>
          </div>
          <TableContainer component={Paper}>
            <Table className="w-full lg:w-[720px]" aria-label="simple table">
              <TableHead className="bg-gray-100">
                <TableRow>
                  <TableCell>User Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell align="right">Suspend</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{loading ? <Loading /> : <Data />}</TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );

  function Data() {
    return (
      <>
        {users.map((user) => (
          <TableRow
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            className={!user.active && "bg-red-100"}
          >
            <TableCell component="th" scope="row">
              {user.name}
            </TableCell>
            <TableCell component="th" scope="row">
              {user.email}
            </TableCell>
            <TableCell component="th" scope="row">
              {user.role}
            </TableCell>
            <TableCell component="th" scope="row">
              {user.active ? "Active" : "Deactivated"}
            </TableCell>
            <TableCell align="right">
              <div className="flex justify-end">
                {user.active ? (
                  <BiTrash
                    className="cursor-pointer"
                    color="red"
                    size={20}
                    onClick={() => activate(user._id)}
                  />
                ) : (
                  <MdGppGood
                    className="text-green-600 cursor-pointer"
                    size={20}
                    onClick={() => activate(user._id)}
                  />
                )}
              </div>
            </TableCell>
          </TableRow>
        ))}
      </>
    );
  }
  function Loading() {
    return (
      <>
        <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
          <TableCell colSpan="5">
            <Skeleton />
          </TableCell>
        </TableRow>

        <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
          <TableCell colSpan="5">
            <Skeleton />
          </TableCell>
        </TableRow>

        <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
          <TableCell colSpan="5">
            <Skeleton />
          </TableCell>
        </TableRow>
      </>
    );
  }
}

export default Users;
