import React from "react";

export class ComponentToPrint extends React.PureComponent {
  render() {
    return (
      <div id="license" className="relative">
        {this.props.factory.investment <= 100000 && (
          <LicenseS newFactory={this.props.factory.new} />
        )}
        {this.props.factory.investment > 100000 &&
          this.props.factory.investment < 250000 && (
            <LicenseC newFactory={this.props.factory.new} />
          )}

        {this.props.factory.investment >= 250000 &&
          this.props.factory.investment < 500000 && (
            <LicenseB newFactory={this.props.factory.new} />
          )}
        {this.props.factory.investment >= 500000 && (
          <LicenseA newFactory={this.props.factory.new} />
        )}

        <h2 className="absolute top-[28%] left-[8%] text-3xl">
          {this.props.factory.license.licenseNo}
        </h2>
        <h2 className="absolute top-[44%] left-[20%] text-3xl ">
          {this.props.factory.name}
        </h2>
        <h2 className="absolute top-[51%] left-[37%] text-3xl">
          {this.props.factory.type}
        </h2>
        <h2 className="absolute top-[57%] left-[42%] text-3xl">
          {this.props.factory.location}
        </h2>
        <h2 className="absolute top-[63%] left-[48%] text-3xl">
          {this.props.factory.shareholders[0].fullName}
        </h2>
        <h2 className="absolute top-[79%] left-[82%] text-2xl">
          {this.props.factory.license.issuedAt.split("T")[0]}
        </h2>
        <h2 className="absolute top-[86.5%] left-[82%] text-2xl">
          {this.props.factory.license.expireAt.split("T")[0]}
        </h2>
      </div>
    );
  }
}

function LicenseS({ newFactory }) {
  return (
    <img
      src={`/certificates/s-${newFactory ? "new" : "renew"}.jpg`}
      className="w-full h-screen"
      alt="license"
    />
  );
}
function LicenseC({ newFactory }) {
  return (
    <img
      src={`/certificates/c-${newFactory ? "new" : "renew"}.jpg`}
      className="w-full h-screen"
      alt="license"
    />
  );
}
function LicenseB({ newFactory }) {
  return (
    <img
      src={`/certificates/b-${newFactory ? "new" : "renew"}.jpg`}
      className="w-full h-screen"
      alt="license"
    />
  );
}
function LicenseA({ newFactory }) {
  return (
    <img
      src={`/certificates/a-${newFactory ? "new" : "renew"}.jpg`}
      className="w-full h-screen"
      alt="license"
    />
  );
}
