import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

// import Box from "@material-ui/material/Box";
import { getAllCategories } from "../Utils/APIFetching";
import { useEffect, useState } from "react";

export default function CategoryDropdown({ value, set, data }) {
  const [cat, setCat] = useState([]);
  const [selected, setSelected] = useState(value);
  useEffect(() => {
    getAllCategories()
      .then((res) => {
        setCat(res.data.categories);
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <FormControl variant="standard" className="grow w-full">
      <InputLabel id="demo-simple-select-standard-label">
        Factory Type
      </InputLabel>
      <Select
        labelId="demo-simple-select-standard-label"
        id="demo-simple-select-standard"
        onChange={(e) => {
          setSelected(e.target.value);
          set({ ...data, type: e.target.value });
        }}
        value={value}
        label="Age"
      >
        {cat.map((item) => (
          <MenuItem value={item.name}>{item.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
