import { Link } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { AuthContext } from "../Utils/AuthContext";
function Header() {
  const { userInfo } = useContext(AuthContext);
  return (
    <div className="bg-white flex items-center justify-between py-3 px-5 border-b border-gray-300">
      <Link to="/dashboard">
        <h1 className="cursor-pointer">Industrial Management System</h1>
      </Link>
      <div className="flex items-center ">
        <div className="bg-sky-500 rounded-full h-9 w-9 mr-1 flex justify-center items-center">
          <h3 className="text-white font-black">{userInfo.name.charAt(0)}</h3>
        </div>
        <div className="text-xs">
          <h1 className="font-black">{userInfo.name}</h1>
          <h1>{userInfo.department}</h1>
        </div>
      </div>
    </div>
  );
}

export default Header;
