import axios from "axios";

const defaultOptions = {
  // baseURL: "http://localhost:8000/api/v1",
  baseURL: "https://moiid-industry.herokuapp.com/api/v1",
  headers: {
    "Content-Type": "application/json",
  },
};

// Create instance axios
let API = axios.create(defaultOptions);

// Set the AUTH token for any request
API.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");
  config.headers.authorization = token ? `Bearer ${token}` : "";
  return config;
});

export async function createUser(body) {
  const res = await API.post("/auth/signup", body);
  return res;
}

export async function login(body) {
  const res = await API.post("/auth/login", body);
  return res;
}

export async function changePassword(body) {
  const res = await API.post("/auth/change", body);
  return res;
}

export async function getUsers() {
  const res = await API.get("/user");
  return res;
}

export async function activateUser(id) {
  const res = await API.post(`/user/activate/${id}`);
  return res;
}
export async function getLogs(page) {
  const res = await API.get(`/user/logs`, { params: { page } });
  return res;
}

export async function getTop() {
  const res = await API.get(`/industry/top`);
  return res;
}

export async function getFactory(slug) {
  const res = await API.get(`/industry/${slug}`);
  return res;
}

export async function deleteFactory(slug) {
  const res = await API.delete(`/industry/${slug}`);
  return res;
}

export async function getFactories(page) {
  const res = await API.get("/industry", { params: { page } });
  return res;
}
export async function createFactory(body) {
  const res = await API.post("/industry", body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return res;
}
export async function createLicense(slug) {
  const res = await API.post(`/industry/license/${slug}`, {});
  return res;
}
export async function renewLicense(slug) {
  const res = await API.put(`/industry/license/${slug}`, {});
  return res;
}
export async function editFactory(slug, body) {
  const res = await API.put(`/industry/${slug}`, body);
  return res;
}
export async function editFactoryDocument(slug, body) {
  const res = await API.put(`/industry/${slug}/document`, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return res;
}
export async function checkName(name, slug) {
  const res = await API.post("/industry/check", { name, slug });
  return res;
}
export async function getReport(params) {
  const res = await API.get("/industry/report", { params: { ...params } });
  return res;
}

export async function getCategories(page) {
  const res = await API.get("/industry/category", { params: { page } });
  return res;
}

export async function getAllCategories() {
  const res = await API.get("/industry/category/all");
  return res;
}
export async function createCategory(category) {
  const res = await API.post("/industry/category", { category });
  return res;
}
export async function deleteCategory(removeId, category) {
  const res = await API.delete("/industry/category", {
    data: { removeId, category },
  });
  return res;
}
