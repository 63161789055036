import Header from "../Partials/Header";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";
import { getLogs } from "../Utils/APIFetching";
import ReactPaginate from "react-paginate";
import Loading from "../Components/Loading";

function Logs() {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    getLogs(currentPage)
      .then((res) => {
        return res;
      })
      .then((res) => {
        console.log(res.data.logs);
        setTotalPages(res.data.totalPages);
        setCurrentPage(res.data.currentPage);
        setLogs(res.data.logs);
        setLoading(false);
      });
  }, [currentPage]);

  function handlePageClick(e) {
    setCurrentPage(e.selected + 1);
  }

  if (loading) return <Loading />;
  return (
    <div>
      <Header />
      <div className="p-5 flex justify-center">
        <div>
          <TableContainer component={Paper}>
            <Table className="lg:w-[720px]" aria-label="simple table">
              <TableHead className="bg-gray-100">
                <TableRow>
                  <TableCell>User</TableCell>
                  <TableCell align="right">Action</TableCell>
                  <TableCell align="right">Description</TableCell>
                  <TableCell align="right">Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {logs.map((log) => (
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    className={log.action === "DELETED" && "bg-red-100"}
                  >
                    <TableCell component="th" scope="row">
                      {log.user.email}
                    </TableCell>
                    <TableCell align="right">{log.action}</TableCell>
                    <TableCell align="right">{log.description}</TableCell>
                    <TableCell align="right">
                      {log.createdAt.split("T")[0]}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {totalPages > 1 && (
            <ReactPaginate
              breakLabel="..."
              nextLabel="next"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={totalPages}
              previousLabel="previous"
              renderOnZeroPageCount={null}
              activeClassName="font-black"
              className="flex justify-center items-center space-x-2 my-4"
              pageClassName="bg-black h-8 w-8 flex justify-center items-center pointer-cursor"
              pageLinkClassName="text-white"
              previousClassName="bg-black h-8 px-3 flex justify-center items-center text-white pointer-cursor"
              nextClassName="bg-black h-8 px-3 flex justify-center items-center text-white pointer-cursor"
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Logs;
