import Header from "../Partials/Header";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import InputLabel from "@mui/material/InputLabel";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import Paper from "@mui/material/Paper";
import { BiTrash } from "react-icons/bi";

import Skeleton from "react-loading-skeleton";
import ReactPaginate from "react-paginate";

import { useEffect, useState } from "react";
import {
  getCategories,
  createCategory,
  deleteCategory,
} from "../Utils/APIFetching";
import toast from "react-hot-toast";
function Category() {
  const [modal, setModal] = useState(false);
  const [category, setCategory] = useState("");
  const [categories, setCategories] = useState([]);
  const [selectCategory, setSelectCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [loading, setLoading] = useState(true);

  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    getAll();
  }, [currentPage]);

  function getAll() {
    getCategories(currentPage)
      .then((res) => {
        return res;
      })
      .then((res) => {
        setTotalPages(res.data.totalPages);
        setCurrentPage(res.data.currentPage);
        setCategories(res.data.categories);
        setLoading(false);
      });
  }

  function handleOnSubmit() {
    toast.promise(createCategory(category), {
      loading: "Creating Category",
      success: (res) => res.data.message,
      error: (err) => err.response.data.message,
    });
    setCategory("");
  }

  function handleCategoryChange() {
    toast.promise(deleteCategory(selectCategory.remove, selectedCategory), {
      loading: "Creating Category",
      success: (res) => res.data.message,
      error: (err) => err.response.data.message,
    });
    const cat = categories.filter(
      (category) => category._id !== selectCategory.remove
    );
    setCategories(cat);
    setModal(false);
  }

  function openModal(id) {
    //id
    const cat = categories.filter((category) => category._id !== id);
    setSelectCategory({ remove: id, category: cat });
    setModal(true);
  }

  function handlePageClick(e) {
    setCurrentPage(e.selected + 1);
  }

  return (
    <div>
      <Header />
      {modal && <Modal />}
      <div className="p-5 flex justify-center">
        <div>
          <div className="py-5">
            <div className="flex">
              <input
                type="text"
                placeholder="Add Category"
                className="grow p-3 bg-white"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              />
              <button
                className="bg-black p-3 text-white"
                onClick={handleOnSubmit}
              >
                Add Category
              </button>
            </div>
          </div>
          <TableContainer component={Paper}>
            <Table className="w-full lg:w-[720px]" aria-label="simple table">
              <TableHead className="bg-gray-100">
                <TableRow>
                  <TableCell>Cateory Name</TableCell>
                  <TableCell align="right">Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{loading ? <Loading /> : <Data />}</TableBody>
            </Table>
          </TableContainer>
          {totalPages > 1 && (
            <ReactPaginate
              breakLabel="..."
              nextLabel="next"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={totalPages}
              previousLabel="previous"
              renderOnZeroPageCount={null}
              activeClassName="font-black"
              className="flex justify-center items-center space-x-2 my-4"
              pageClassName="bg-black h-8 w-8 flex justify-center items-center pointer-cursor"
              pageLinkClassName="text-white"
              previousClassName="bg-black h-8 px-3 flex justify-center items-center text-white pointer-cursor"
              nextClassName="bg-black h-8 px-3 flex justify-center items-center text-white pointer-cursor"
            />
          )}
        </div>
      </div>
    </div>
  );

  function Data() {
    return (
      <>
        {categories.map((category) => (
          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell component="th" scope="row">
              {category.name}
            </TableCell>
            <TableCell align="right">
              <div className="flex justify-end">
                <BiTrash
                  className="cursor-pointer"
                  color="red"
                  onClick={() => openModal(category._id)}
                />
              </div>
            </TableCell>
          </TableRow>
        ))}
      </>
    );
  }
  function Loading() {
    return (
      <>
        <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
          <TableCell colSpan="2">
            <Skeleton />
          </TableCell>
        </TableRow>

        <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
          <TableCell colSpan="2">
            <Skeleton />
          </TableCell>
        </TableRow>

        <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
          <TableCell colSpan="2">
            <Skeleton />
          </TableCell>
        </TableRow>
      </>
    );
  }

  function Modal() {
    return (
      <div className="flex justify-center">
        <div className="fixed top-0 bg-black w-full h-screen opacity-50"></div>
        <div className="absolute bg-white top-52 m-auto p-10 text-center w-2/5">
          <div>
            <h2 className="text-xl">
              Are you sure you want to delete this category?
            </h2>
            <p className="pt-2">
              Please select which category to change factories
              <br /> with this category?
            </p>
            <FormControl variant="standard" className="w-full">
              <InputLabel id="demo-simple-select-standard-label">
                Select a category
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.target.value)}
                label="cat"
              >
                {selectCategory.category.map((category) => (
                  <MenuItem value={category.name}>{category.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <div className="space-x-2 mt-4">
              <button
                className="bg-green-500 text-white text-lg px-6 py-2 "
                onClick={handleCategoryChange}
              >
                Change
              </button>
              <button
                className="bg-black text-white text-lg px-6 py-2"
                onClick={() => setModal(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Category;
