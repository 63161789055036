import Header from "../Partials/Header";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import CategoryDropdown from "../Components/CategoryDropdown";
import { useEffect, useState } from "react";
import { checkName, createFactory } from "../Utils/APIFetching";
import toast from "react-hot-toast";

function Form() {
  const [data, setData] = useState({
    shareholders: [{}],
  });
  // useEffect(() => {
  //   console.log(data.shareholders[0].name);
  // }, []);
  function checkFactoryName(e) {
    e.preventDefault();
    toast.promise(checkName(data.name), {
      loading: "Checking name avalibility",
      success: (res) => res.data.message,
      error: (err) => err.response.data.message,
    });
  }

  function handleOnSubmit(e) {
    e.preventDefault();
    let formData = new FormData();
    for (let key in data) {
      if ([key] == "shareholders") {
        for (let i = 0; i < data[key].length; i++) {
          for (let newKey in data["shareholders"][i]) {
            formData.append(
              `shareholders[${i}][${newKey}]`,
              data["shareholders"][i][newKey]
            );
          }
        }
      } else {
        formData.append([key], data[key]);
      }
    }
    // console.log(formData);
    console.log(formData);
    toast.promise(createFactory(formData), {
      loading: "Saving Factory...",
      success: (res) => res.data.message,
      error: (err) => err.response.data.message,
    });
  }

  function handleDocument(e, key) {
    // const file = e.target.files[0];
    // const reader = new FileReader();
    // reader.readAsDataURL(file);
    // reader.onloadend = () => {
    //   setData({ ...data, [key]: reader.result });
    // };

    const file = e.target.files[0];
    setData({ ...data, [key]: file });
  }

  function addShareholder(e) {
    e.preventDefault();
    var shar = data;
    shar.shareholders = [...data.shareholders, { fullName: "" }];
    setData({ ...shar });
    toast.success("Create new shareholder");
  }

  function removeShareholder(e, index) {
    e.preventDefault();
    const newArray = data;
    newArray.shareholders.splice(index, 1);
    setData({ ...newArray });
    toast.success("Removed shareholder");
  }

  function shareholderHandleOnChange(index, value, key) {
    const newArray = data;
    newArray.shareholders[index][`${key}`] = value;
    setData({ ...newArray });
  }

  //date of registration

  return (
    <div>
      <Header />
      <div className=" py-8 flex justify-center">
        <div className="lg:w-1/2 ">
          <form autoComplete="off" onSubmit={handleOnSubmit}>
            <div className="bg-white p-5">
              <div>
                <h1 className="text-xl">Add new factory</h1>
                <p className="text-sm py-2">
                  Fill out these 5 steps to create a new factory. Make sure you{" "}
                  <br />
                  check to see if name is not taken
                </p>
              </div>

              <div className="mt-5">
                <div className="flex">
                  <input
                    type="text"
                    placeholder="Factory Name"
                    className="grow p-2 bg-gray-100"
                    onChange={(e) => setData({ ...data, name: e.target.value })}
                  />
                  <button
                    className="bg-black p-2 text-white"
                    onClick={(e) => checkFactoryName(e)}
                  >
                    Check Name
                  </button>
                </div>
                <div className="flex space-x-5">
                  <CategoryDropdown
                    value={data.type}
                    set={setData}
                    data={data}
                  />
                  <TextField
                    id="standard-basic"
                    label="Proposed Location"
                    variant="standard"
                    className="grow"
                    onChange={(e) =>
                      setData({ ...data, location: e.target.value })
                    }
                  />
                </div>
                <div className="flex space-x-5">
                  <FormControl variant="standard" className="grow">
                    <InputLabel id="demo-simple-select-standard-label">
                      Ownership Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      // value={data.ownership}
                      onChange={(e) =>
                        setData({ ...data, ownership: e.target.value })
                      }
                      label="Age"
                    >
                      <MenuItem value="Local Partnership">
                        Local Partnership
                      </MenuItem>
                      <MenuItem value="Foreign Partnership">
                        Foreign Partnership
                      </MenuItem>
                      <MenuItem value="Joint Venture">Joint Venture</MenuItem>
                      <MenuItem value="Local Solo Partnership">
                        Local Solo Partnership
                      </MenuItem>
                      <MenuItem value="Foreign Solo Partnership">
                        Foreign Solo Partnership
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    id="standard-basic"
                    label="Registration Date"
                    variant="standard"
                    className="grow"
                    type="date"
                    onChange={(e) =>
                      setData({ ...data, registered: e.target.value })
                    }
                  />
                </div>
                <div className="flex space-x-5">
                  <TextField
                    id="standard-basic"
                    label="Phone"
                    variant="standard"
                    className="grow"
                    onChange={(e) =>
                      setData({ ...data, phone: e.target.value })
                    }
                  />
                  <TextField
                    id="standard-basic"
                    label="Email"
                    variant="standard"
                    className="grow"
                    onChange={(e) =>
                      setData({ ...data, email: e.target.value })
                    }
                  />
                </div>

                <div className="flex space-x-5">
                  <TextField
                    id="standard-basic"
                    label="Tell"
                    variant="standard"
                    className="grow"
                    onChange={(e) => setData({ ...data, tel: e.target.value })}
                  />
                  <TextField
                    id="standard-basic"
                    label="Website"
                    variant="standard"
                    className="grow"
                    onChange={(e) =>
                      setData({ ...data, website: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>
            {/* SECTION TWO */}
            <div className="bg-white p-5 mt-5">
              <h2 className="text-center mt-2">
                Step 2: Applicant Information
              </h2>
              <div className="flex space-x-5">
                <TextField
                  id="standard-basic"
                  label="Applicant Name"
                  variant="standard"
                  className="grow"
                  onChange={(e) =>
                    setData({ ...data, applicant: e.target.value })
                  }
                />

                <FormControl variant="standard" className="grow">
                  <InputLabel id="demo-simple-select-standard-label">
                    Legal Document
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    // value={data.legalDocument}
                    onChange={(e) =>
                      setData({ ...data, legalDocument: e.target.value })
                    }
                    label="Legal Document"
                  >
                    <MenuItem value="ID">ID</MenuItem>
                    <MenuItem value="Passport">Passport</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div>
                <TextField
                  id="standard-basic"
                  label="ID/Passport Number"
                  variant="standard"
                  className="w-full"
                  onChange={(e) =>
                    setData({ ...data, legalDocumentNo: e.target.value })
                  }
                />
              </div>
              <div className="flex space-x-5">
                <TextField
                  id="standard-basic"
                  label="Telephone Number"
                  variant="standard"
                  className="grow"
                  onChange={(e) =>
                    setData({ ...data, applicantTel: e.target.value })
                  }
                />
                <TextField
                  id="standard-basic"
                  label="Citizenship"
                  variant="standard"
                  className="grow"
                  onChange={(e) =>
                    setData({ ...data, citizenship: e.target.value })
                  }
                />
              </div>
            </div>
            {/* SECTION TWO */}
            {/* SECTION THREE */}
            <div className="bg-white p-5 mt-5">
              <h2 className="text-center mt-2">Step 3: Shareholders</h2>
              {data.shareholders.map((value, index) => (
                <div className="pb-7">
                  <div className="flex justify-between my-1">
                    <div className="py-3 px-4  bg-black text-white w-fit">
                      {index + 1}
                    </div>
                    {data.shareholders.length > 1 && (
                      <button
                        className="py-3 px-4  bg-black text-white w-fit"
                        onClick={(e) => removeShareholder(e, index)}
                      >
                        Remove
                      </button>
                    )}
                  </div>
                  <div className="flex space-x-5">
                    <TextField
                      id="standard-basic"
                      label="Full Name"
                      variant="standard"
                      className="grow"
                      value={data.shareholders[index].fullName}
                      onChange={(e) =>
                        shareholderHandleOnChange(
                          index,
                          e.target.value,
                          "fullName"
                        )
                      }
                    />

                    <FormControl variant="standard" className="grow">
                      <InputLabel id="demo-simple-select-standard-label">
                        Legal Document
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={data.shareholders[index].legalDocument}
                        onChange={(e) =>
                          shareholderHandleOnChange(
                            index,
                            e.target.value,
                            "legalDocument"
                          )
                        }
                        label="Legal Document"
                      >
                        <MenuItem value="ID">ID</MenuItem>
                        <MenuItem value="Passport">Passport</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div>
                    <TextField
                      id="standard-basic"
                      label="ID/Passport Number"
                      variant="standard"
                      className="w-full"
                      value={data.shareholders[index].legalDocumentNo}
                      onChange={(e) =>
                        shareholderHandleOnChange(
                          index,
                          e.target.value,
                          "legalDocumentNo"
                        )
                      }
                    />
                  </div>
                  <div className="flex space-x-5">
                    <TextField
                      id="standard-basic"
                      label="Nationality"
                      variant="standard"
                      className="grow"
                      value={data.shareholders[index].nationality}
                      onChange={(e) =>
                        shareholderHandleOnChange(
                          index,
                          e.target.value,
                          "nationality"
                        )
                      }
                    />
                    <TextField
                      id="standard-basic"
                      label="Citizenship"
                      variant="standard"
                      className="grow"
                      value={data.shareholders[index].citizenship}
                      onChange={(e) =>
                        shareholderHandleOnChange(
                          index,
                          e.target.value,
                          "citizenship"
                        )
                      }
                    />
                  </div>
                  <div className="flex space-x-5">
                    <TextField
                      id="standard-basic"
                      label="Nationality"
                      variant="standard"
                      className="grow"
                      type="date"
                      value={data.shareholders[index].dateOfBirth}
                      onChange={(e) =>
                        shareholderHandleOnChange(
                          index,
                          e.target.value,
                          "dateOfBirth"
                        )
                      }
                    />
                    <TextField
                      id="standard-basic"
                      label="Home address"
                      variant="standard"
                      className="grow"
                      value={data.shareholders[index].address}
                      onChange={(e) =>
                        shareholderHandleOnChange(
                          index,
                          e.target.value,
                          "address"
                        )
                      }
                    />
                  </div>
                  <div className="flex space-x-5">
                    <TextField
                      id="standard-basic"
                      label="Tell / Mobile"
                      variant="standard"
                      className="grow"
                      value={data.shareholders[index].tell}
                      onChange={(e) =>
                        shareholderHandleOnChange(index, e.target.value, "tell")
                      }
                    />
                    <TextField
                      id="standard-basic"
                      label="Email"
                      variant="standard"
                      className="grow"
                      value={data.shareholders[index].email}
                      onChange={(e) =>
                        shareholderHandleOnChange(
                          index,
                          e.target.value,
                          "email"
                        )
                      }
                    />
                  </div>
                </div>
              ))}
              <div className="flex justify-center">
                <button
                  className="bg-black p-2 text-white"
                  onClick={(e) => addShareholder(e)}
                >
                  Add Shareholder
                </button>
              </div>
            </div>
            {/* SECTION THREE */}
            {/* SECTION FOUR */}
            <div className="bg-white p-5 mt-5">
              <h2 className="text-center mt-2">Step 4: Production Capacity</h2>
              <div className="flex space-x-5">
                <TextField
                  id="standard-basic"
                  label="Main manufacturing activity"
                  variant="standard"
                  className="grow"
                  onChange={(e) =>
                    setData({ ...data, activity: e.target.value })
                  }
                />
                <TextField
                  id="standard-basic"
                  label="Product to be Manufactured"
                  variant="standard"
                  className="grow"
                  onChange={(e) =>
                    setData({ ...data, product: e.target.value })
                  }
                />
              </div>
              <div className="flex space-x-5">
                <TextField
                  id="standard-basic"
                  label="Production Capacity"
                  variant="standard"
                  className="grow"
                  onChange={(e) =>
                    setData({ ...data, capacity: e.target.value })
                  }
                />
                <TextField
                  id="standard-basic"
                  label="Payback Period"
                  variant="standard"
                  className="grow"
                  onChange={(e) =>
                    setData({ ...data, payback: e.target.value })
                  }
                />
              </div>
              <div className="flex space-x-5">
                <TextField
                  id="standard-basic"
                  label="Daily Capacity"
                  variant="standard"
                  className="grow"
                  onChange={(e) => setData({ ...data, daily: e.target.value })}
                />
                <TextField
                  id="standard-basic"
                  label="Weekly Capacity"
                  variant="standard"
                  className="grow"
                  onChange={(e) => setData({ ...data, weekly: e.target.value })}
                />
                <TextField
                  id="standard-basic"
                  label="Yearly Period"
                  variant="standard"
                  className="grow"
                  onChange={(e) => setData({ ...data, yearly: e.target.value })}
                />
              </div>
              <div className="flex space-x-5">
                <TextField
                  id="standard-basic"
                  label="Power load"
                  variant="standard"
                  className="grow"
                  onChange={(e) =>
                    setData({ ...data, powerLoad: e.target.value })
                  }
                />
                <TextField
                  id="standard-basic"
                  label="Power Source"
                  variant="standard"
                  className="grow"
                  onChange={(e) =>
                    setData({ ...data, powerSource: e.target.value })
                  }
                />
              </div>
            </div>
            {/* SECTION FOUR */}
            {/* SECTION FIVE */}
            <div className="bg-white p-5 mt-5">
              <h2 className="text-center mt-2">Step 5: Raw Material</h2>
              <div className="flex space-x-5">
                <TextField
                  id="standard-basic"
                  label="Raw Materials"
                  variant="standard"
                  className="grow"
                  onChange={(e) => setData({ ...data, raw: e.target.value })}
                />
              </div>
              <div className="flex space-x-5">
                <FormControl variant="standard" className="grow">
                  <InputLabel id="demo-simple-select-standard-label">
                    Raw Material Origin
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    onChange={(e) =>
                      setData({ ...data, origin: e.target.value })
                    }
                    label="Raw Material Origin"
                  >
                    <MenuItem value="Domestic">Domestic</MenuItem>
                    <MenuItem value="Foreign">Foreign</MenuItem>
                    <MenuItem value="Both">Both</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
            {/* SECTION FIVE */}
            {/* SECTION SIX */}
            <div className="bg-white p-5 mt-5">
              <h2 className="text-center mt-2">
                Step 6: Capital Specification
              </h2>
              <div className="flex space-x-5">
                <TextField
                  id="standard-basic"
                  label="Proposed Factory Investment($)"
                  variant="standard"
                  className="grow"
                  onChange={(e) =>
                    setData({ ...data, investment: e.target.value })
                  }
                />
                <TextField
                  id="standard-basic"
                  label="Machinery Costs($)"
                  variant="standard"
                  className="grow"
                  onChange={(e) =>
                    setData({ ...data, machinery: e.target.value })
                  }
                />
              </div>
              <div className="flex space-x-5">
                {/* <TextField
                  id="standard-basic"
                  label="Total Factory Value($)"
                  variant="standard"
                  className="grow"
                  onChange={(e) =>
                    setData({ ...data, factory: e.target.value })
                  }
                /> */}
                <TextField
                  id="standard-basic"
                  label="Plant Machinery Costs($)"
                  variant="standard"
                  className="grow"
                  onChange={(e) => setData({ ...data, plant: e.target.value })}
                />
              </div>
              <div className="flex space-x-5">
                <TextField
                  id="standard-basic"
                  label="Land Value($)"
                  variant="standard"
                  className="grow"
                  onChange={(e) => setData({ ...data, land: e.target.value })}
                />
              </div>
            </div>
            {/* SECTION SIX */}
            {/* SECTION SIX */}
            <div className="bg-white p-5 mt-5">
              <h2 className="text-center mt-2">Step 7: Upload Documents</h2>
              <div className="flex space-x-5">
                <div className="border-b py-3">
                  <label className="font-semibold text-sm">
                    Factory Profile:
                  </label>
                  <input
                    type="file"
                    onChange={(e) => {
                      handleDocument(e, "profile");
                    }}
                    accept="application/pdf"
                  />
                </div>
                <div className="border-b py-3">
                  <label className="font-semibold text-sm">
                    Feasibility Study:
                  </label>
                  <input
                    type="file"
                    onChange={(e) => {
                      handleDocument(e, "study");
                    }}
                    accept="application/pdf"
                  />
                </div>
              </div>
              <div className="flex space-x-5">
                <div className="border-b py-3">
                  <label className="font-semibold text-sm">Company Law:</label>
                  <input
                    type="file"
                    onChange={(e) => {
                      handleDocument(e, "law");
                    }}
                    accept="application/pdf"
                  />
                </div>
                <div className="border-b py-3">
                  <label className="font-semibold text-sm">National ID:</label>
                  <input
                    type="file"
                    onChange={(e) => {
                      handleDocument(e, "nationalID");
                    }}
                    accept="application/pdf"
                  />
                </div>
              </div>
            </div>
            {/* SECTION SIX */}
            <div className="mt-10">
              <button className="bg-black p-3 text-white w-full" type="submit">
                Save Factory
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Form;
