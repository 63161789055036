import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Skeleton from "react-loading-skeleton";

import { MdRemoveRedEye } from "react-icons/md";
import { Link } from "react-router-dom";

function LatestList({ factories, loading }) {
  return (
    <div>
      <TableContainer component={Paper}>
        <Table
          // sx={{ minWidth: 420, maxWidth: 420 }}
          // sx={{ minWidth: 420, maxWidth: 420 }}
          className="w-full"
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow className="bg-gray-100">
              <TableCell>Factory Name</TableCell>
              <TableCell>Sector</TableCell>
              <TableCell align="right">View</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{loading ? <Loading /> : <Data />}</TableBody>
        </Table>
      </TableContainer>
    </div>
  );

  function Data() {
    return (
      <>
        {factories.map((factory, index) => (
          <TableRow
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            key={index}
          >
            <TableCell component="th" scope="row">
              {factory.name}
            </TableCell>
            <TableCell>{factory.type}</TableCell>
            <TableCell align="right">
              <Link to={`/details/${factory.slug}`}>
                <div className="flex justify-end">
                  <MdRemoveRedEye size={16} className="text-blue-600" />
                </div>
              </Link>
            </TableCell>
          </TableRow>
        ))}
      </>
    );
  }
  function Loading() {
    return (
      <>
        <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
          <TableCell colSpan="3">
            <Skeleton />
          </TableCell>
        </TableRow>

        <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
          <TableCell colSpan="3">
            <Skeleton />
          </TableCell>
        </TableRow>

        <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
          <TableCell colSpan="3">
            <Skeleton />
          </TableCell>
        </TableRow>
      </>
    );
  }
}

export default LatestList;
