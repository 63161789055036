import Header from "../Partials/Header";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { changePassword } from "../Utils/APIFetching";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
function Settings() {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({});
  const [values, setValues] = useState("");

  function handleOnSubmit(e) {
    e.preventDefault();
    toast
      .promise(changePassword(inputs), {
        loading: "Chaning password...",
        success: (res) => res.data.message,
        error: (err) => err.response.data.message,
      })
      .then(() => {
        navigate("/dashboard");
      });
  }
  return (
    <div>
      <Header />
      <div className=" py-8 flex justify-center">
        <div className="w-full mx-5 lg:mx-0 lg:w-2/5 bg-white rounded-md p-5">
          <div>
            <h1 className="text-xl text-center">Change Password</h1>
          </div>
          <form className="mt-5" autoComplete="off" onSubmit={handleOnSubmit}>
            <div className="flex space-x-5 px-5">
              <TextField
                id="standard-basic"
                label="Old Password"
                variant="standard"
                className="grow"
                type="password"
                onChange={(e) =>
                  setInputs({ ...inputs, oldPassword: e.target.value })
                }
              />
            </div>
            <div className="flex space-x-5 px-5">
              <TextField
                id="standard-basic"
                label="New Password"
                variant="standard"
                className="grow"
                type="password"
                onChange={(e) =>
                  setInputs({ ...inputs, newPassword: e.target.value })
                }
              />
            </div>
            <div className="flex space-x-5 px-5">
              <TextField
                id="standard-basic"
                label="Confirm New Password"
                variant="standard"
                className="grow"
                type="password"
                onChange={(e) =>
                  setInputs({ ...inputs, confirmNewPassword: e.target.value })
                }
              />
            </div>
            <div className="mt-5 flex justify-center">
              <button className="bg-black p-3 text-white" type="submit">
                Change Password
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Settings;
