import { login } from "../Utils/APIFetching";
import { useState, useContext } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../Utils/AuthContext";
import jwt_decode from "jwt-decode";
function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { setUser, setUserInfo } = useContext(AuthContext);
  function handleOnSubmit() {
    toast
      .promise(login({ email, password }), {
        loading: "Signing in ....",
        success: (res) => res.data.message,
        error: (err) => err.response.data.message,
      })
      .then((res) => {
        localStorage.setItem("token", res.data.token);
        var decoded = jwt_decode(res.data.token);
        setUser(true);
        setUserInfo(decoded);
        navigate("/dashboard");
      });
  }
  return (
    <div className="h-screen flex flex-col items-center ">
      <div className="flex items-center space-x-3 mx-5 lg:w-4/12 my-3">
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/5/5d/Emblem_of_Somaliland.svg"
          alt="somaliland"
          className="h-32 py-5"
        />
        <div className="border-l-2 border-black h-fit pl-4 ">
          <h2 className="text-xl">
            Ministry of Investment and Industrial Development
          </h2>
          <h2 className="text-gray-800">Industrial Permit Management System</h2>
        </div>
      </div>
      <div className="bg-white w-full lg:w-4/12 h-fit mx-5">
        <div className="mx-10 my-5">
          <h1 className="text-2xl text-center py-4">Login</h1>

          <div className="space-y-2">
            <div>
              <input
                type="text"
                placeholder="Email"
                className="p-3  w-full bg-gray-50"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div>
              <input
                type="password"
                placeholder="Password"
                className="p-3 w-full bg-gray-50"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="pt-3">
              <button
                className="bg-black text-white text-lg px-8 py-3 w-full"
                onClick={handleOnSubmit}
              >
                Login
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
