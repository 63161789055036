import Header from "../Partials/Header";
import { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { getFactory } from "../Utils/APIFetching";
import { NumericFormat } from "react-number-format";
import { BiEditAlt } from "react-icons/bi";
import { Link } from "react-router-dom";
import Loading from "../Components/Loading";
import {
  createLicense,
  editFactoryDocument,
  deleteFactory,
} from "../Utils/APIFetching";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../Utils/AuthContext";
import { Document, Page } from "react-pdf";

function Details() {
  const navigate = useNavigate();
  const { slug } = useParams();
  const [factory, setFactory] = useState({});
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [documentModal, setDocumentModal] = useState(false);
  const [editDocumentModal, setEditDocumentModal] = useState({
    document: "",
    modal: false,
  });
  const [document, setDocument] = useState({});
  const { userInfo } = useContext(AuthContext);

  useEffect(() => {
    getFactoryDetails();
  }, []);

  function getFactoryDetails() {
    getFactory(slug)
      .then((res) => {
        return res;
      })
      .then((res) => {
        setFactory(res.data.factory);
        setLoading(false);
      });
  }

  function handleDocument(e, key) {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append([key], file);
    setDocument(formData);
    // const reader = new FileReader();
    // reader.readAsDataURL(file);
    // reader.onloadend = () => {
    //   setDocument({ [key]: reader.result });
    // };
  }

  function handleOnEditDocument() {
    //CODE TO EDIT DOCUMENT
    console.log(document);
    toast.promise(editFactoryDocument(slug, document), {
      loading: "Editing Document",
      success: (res) => {
        setFactory(res.data.factory);

        return res.data.message;
      },
      error: (err) => err.response.data.message,
    });
    setDocument({});
    setEditDocumentModal({
      document: "",
      modal: false,
    });
  }

  async function handleCreateLicense() {
    toast
      .promise(createLicense(slug), {
        loading: "Creating License",
        success: (res) => res.data.message,
        error: (err) => err.response.data.message,
      })
      .then(() => {
        navigate(`/license/${slug}`);
      });
  }

  async function handleDeleteFactory() {
    setModal(false);
    //delete code
    toast
      .promise(deleteFactory(slug), {
        loading: "Deleting Factory",
        success: (res) => res.data.message,
        error: (err) => err.response.data.message,
      })
      .then(() => {
        navigate(`/list`);
      });
  }

  if (loading) {
    return <Loading />;
  }
  return (
    <div className="relative">
      <Header />
      {/* TOP SECTION */}
      {modal && <DeleteModal />}
      {documentModal.modal && <DocumentsModal />}
      {editDocumentModal.modal && <EditDocumentModal />}
      <div className="">
        <div className="py-5 px-10 grid grid-cols-2 lg:grid-cols-4 gap-4 ">
          {/* COMPANY NAME */}
          <div className="col-span-2 lg:col-span-1 p-5 bg-white flex flex-col items-center justify-center">
            <div className="bg-gray-200 rounded-full h-20 w-20 mr-1 flex justify-center items-center ">
              <h2 className="text-2xl"> {factory.name.charAt(0)}</h2>
            </div>
            <h2 className=" text-2xl">{factory.name}</h2>
            <div className="py-1">
              {factory.license && (
                <>
                  {new Date().toISOString() > factory.license.expireAt && (
                    <div className="bg-red-500 text-sm py-1 px-2 text-white">
                      Permit Expired
                    </div>
                  )}
                </>
              )}
            </div>
            <Link to={`/edit/${slug}`}>
              <button className="bg-sky-400 h-8 w-8 flex justify-center items-center">
                <BiEditAlt color="white" size={25} />
              </button>
            </Link>
          </div>
          {/* COMPANY NAME */}
          {/* COMPANY DETAILS */}
          <div className="col-span-2 bg-white p-5">
            <div className="grid grid-cols-2 gap-4">
              <div className="border-r ">
                <h2 className="text-sky-700">Factory Name:</h2>
                <h2>{factory.name}</h2>
                <h2 className="text-sky-700">Factory Type:</h2>
                <h2>{factory.type}</h2>
                <h2 className="text-sky-700">Proposed Location:</h2>
                <h2>{factory.location}</h2>
                <h2 className="text-sky-700">Type Ownership</h2>
                <h2>{factory.ownership}</h2>
              </div>
              <div>
                <h2 className="text-sky-700">Phone:</h2>
                <h2>{factory.phone}</h2>
                <h2 className="text-sky-700">Email:</h2>
                <h2>{factory.email}</h2>
                <h2 className="text-sky-700">Tell:</h2>
                <h2>{factory.tel}</h2>
                <h2 className="text-sky-700">Website:</h2>
                <h2>{factory.website}</h2>
              </div>
            </div>
          </div>
          {/* COMPANY DETAILS */}
          {/* LICENSE BUTTONS */}
          <div className="grid col-span-2 lg:col-span-1 lg:grid-rows-2 gap-2">
            {factory.license ? (
              <Link
                to={`/license/${factory.slug}`}
                className="bg-blue-500 flex justify-center items-center h-28 "
              >
                <h2 className="text-white text-xl">View Permit</h2>
              </Link>
            ) : (
              <>
                {(userInfo.role === "Admin" ||
                  userInfo.role === "Licenser") && (
                  <button
                    className="bg-green-500 flex justify-center items-center h-28"
                    onClick={handleCreateLicense}
                  >
                    <h2 className="text-white text-xl">Create Permit</h2>
                  </button>
                )}
              </>
            )}
            {userInfo.role === "Admin" && (
              <button
                className="bg-red-500 flex justify-center items-center h-28"
                onClick={() => setModal(true)}
              >
                <h2 className="text-white text-xl">Delete Factory</h2>
              </button>
            )}
          </div>
          {/* LICENSE BUTTONS */}
        </div>
        {/* {STATS} */}
        <div className="col-span-4  px-10 ">
          <div className="grid grid-cols-2 lg:grid-cols-4 gap-4">
            <div className="bg-white p-5 text-center">
              <h2>Proposed Investment</h2>
              <h2 className="font-bold text-gray-700 text-xl py-2">
                <NumericFormat
                  value={factory.investment}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                />
              </h2>
            </div>
            <div className="bg-white p-5 text-center">
              <h2>Machinery Costs</h2>
              <h2 className="font-bold text-gray-700 text-xl py-2">
                <NumericFormat
                  value={factory.machinery}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                />
              </h2>
            </div>
            {/* <div className="bg-white p-5 text-center">
              <h2>Factory Value</h2>
              <h2 className="font-bold text-gray-700 text-xl py-2">
                <NumericFormat
                  value={factory.factory}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                />
              </h2>
            </div> */}
            <div className="bg-white p-5 text-center">
              <h2>Factory Land Value</h2>
              <h2 className="font-bold text-gray-700 text-xl py-2">
                <NumericFormat
                  value={factory.land}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                />
              </h2>
            </div>
            <div className="bg-white p-5 text-center">
              <h2>Factory Land Value</h2>
              <h2 className="font-bold text-gray-700 text-xl py-2">
                <NumericFormat
                  value={factory.plant}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                />
              </h2>
            </div>
          </div>
        </div>
        {/* {STATS} */}
        {/* APPLICANT DETAILS */}
        <div className="grid lg:grid-cols-4 gap-4 my-4  px-10">
          <div className="col-span-2 lg:col-span-1 bg-white p-5">
            <h2 className="text-center text-xl py-2">Applicant Information</h2>
            <h2 className="text-sky-700">Applicant Name:</h2>
            <h2>{factory.applicant}</h2>
            <h2 className="text-sky-700">ID/Passport:</h2>
            <h2>{factory.legalDocument}</h2>
            <h2 className="text-sky-700">ID/Passport Number:</h2>
            <h2>{factory.legalDocumentNo}</h2>
            <h2 className="text-sky-700">Tell:</h2>
            <h2>{factory.applicantTel}</h2>
            <h2 className="text-sky-700">Citizenship:</h2>
            <h2>{factory.citizenship}</h2>
          </div>
          <div className="col-span-2 bg-white p-5">
            <div className="grid grid-cols-2 gap-4 ">
              <div className="border-r ">
                <h2 className="text-sky-700">Manufacturing Activity:</h2>
                <h2>{factory.activity}</h2>
                <h2 className="text-sky-700">Product to be Manufactured:</h2>
                <h2>{factory.product}</h2>
                <h2 className="text-sky-700">Production Capacity:</h2>
                <h2>{factory.capacity}</h2>
                <h2 className="text-sky-700">Payback Period</h2>
                <h2>{factory.payback}</h2>
              </div>
              <div>
                <h2 className="text-sky-700">Daily:</h2>
                <h2>{factory.daily}</h2>
                <h2 className="text-sky-700">Weekly:</h2>
                <h2>{factory.weekly}</h2>
                <h2 className="text-sky-700">Yearly:</h2>
                <h2>{factory.yearly}</h2>
                <h2 className="text-sky-700">Power Load:</h2>
                <h2>{factory.powerLoad}</h2>
                <h2 className="text-sky-700">Power Source:</h2>
                <h2>{factory.powerSource}</h2>
              </div>
            </div>
          </div>
          <div className="col-span-2 lg:col-span-1 bg-white p-5">
            <h2 className="text-center text-xl py-2 ">
              Raw Material Specification
            </h2>
            <h2 className="text-sky-700">Type of raw material:</h2>
            <h2>{factory.raw}</h2>
            <h2 className="text-sky-700">
              Where does the raw material come from:
            </h2>
            <h2>{factory.origin}</h2>
          </div>
        </div>
        {/* APPLICANT DETAILS */}
        {/* {DOCUMENTS} */}
        <div className="col-span-4  px-10 ">
          <div className="grid grid-cols-2 lg:grid-cols-4 gap-4">
            <div className="grid grid-row-2 gap-2">
              <button
                className="bg-green-500 flex justify-center items-center w-full py-4"
                onClick={() =>
                  setDocumentModal({
                    name: "profile",
                    document: "Factory Profile",
                    modal: true,
                  })
                }
              >
                <h2 className="text-white text-xl">View Factory Profile</h2>
              </button>
              <button
                className="bg-sky-500 flex justify-center items-center w-full py-4"
                onClick={() =>
                  setEditDocumentModal({
                    name: "profile",
                    document: "Factory Profile",
                    modal: true,
                  })
                }
              >
                <h2 className="text-white text-xl">Edit Factory Profile</h2>
              </button>
            </div>
            <div className="grid grid-row-2 gap-2">
              <button
                className="bg-green-500 flex justify-center items-center w-full py-4"
                onClick={() =>
                  setDocumentModal({
                    name: "study",
                    document: "Feasibility Study",
                    modal: true,
                  })
                }
              >
                <h2 className="text-white text-xl">View Feasibility Study</h2>
              </button>
              <button
                className="bg-sky-500 flex justify-center items-center w-full py-4"
                onClick={() =>
                  setEditDocumentModal({
                    name: "study",
                    document: "Feasibility Study",
                    modal: true,
                  })
                }
              >
                <h2 className="text-white text-xl">Edit Feasibility Study</h2>
              </button>
            </div>
            <div className="grid grid-row-2 gap-2">
              <button
                className="bg-green-500 flex justify-center items-center w-full py-4"
                onClick={() =>
                  setDocumentModal({
                    name: "law",
                    document: "Company Law",
                    modal: true,
                  })
                }
              >
                <h2 className="text-white text-xl">View Company Law</h2>
              </button>
              <button
                className="bg-sky-500 flex justify-center items-center w-full py-4"
                onClick={() =>
                  setEditDocumentModal({
                    name: "law",
                    document: "Company Law",
                    modal: true,
                  })
                }
              >
                <h2 className="text-white text-xl">Edit Company Law</h2>
              </button>
            </div>
            <div className="grid grid-row-2 gap-2">
              <button
                className="bg-green-500 flex justify-center items-center w-full py-4"
                onClick={() =>
                  setDocumentModal({
                    name: "nationalID",
                    document: "National ID",
                    modal: true,
                  })
                }
              >
                <h2 className="text-white text-xl">View National ID</h2>
              </button>
              <button
                className="bg-sky-500 flex justify-center items-center w-full py-4"
                onClick={() =>
                  setEditDocumentModal({
                    name: "nationalID",
                    document: "National ID",
                    modal: true,
                  })
                }
              >
                <h2 className="text-white text-xl">Edit National ID</h2>
              </button>
            </div>
          </div>
        </div>
        {/* {DOCUMENTS} */}
        {/* SHAREHOLDERS */}

        <div className=" my-4 bg-black p-3 text-white text-center text-2xl ">
          Shareholders
        </div>
        <div className="grid lg:grid-cols-2 gap-4 px-10">
          {factory.shareholders.map((shareholder) => (
            <div className="bg-white p-5 ">
              <h2 className="text-center text-xl py-2">
                {shareholder.fullName}
              </h2>

              <div className="grid grid-cols-2 gap-4 ">
                <div className="border-r ">
                  <h2 className="text-sky-700">Legal Document:</h2>
                  <h2>{shareholder.legalDocument}</h2>
                  <h2 className="text-sky-700">Legal Document Number:</h2>
                  <h2>{shareholder.legalDocumentNo}</h2>
                  <h2 className="text-sky-700">Nationality:</h2>
                  <h2>{shareholder.nationality}</h2>
                  <h2 className="text-sky-700">Citizenship</h2>
                  <h2>{shareholder.citizenship}</h2>
                </div>
                <div>
                  <h2 className="text-sky-700">Date of birth:</h2>
                  <h2>{shareholder.dateOfBirth.split("T")[0]}</h2>
                  <h2 className="text-sky-700">Address:</h2>
                  <h2>{shareholder.address}</h2>
                  <h2 className="text-sky-700">Tell:</h2>
                  <h2>{shareholder.tell}</h2>
                  <h2 className="text-sky-700">Email:</h2>
                  <h2>{shareholder.email}</h2>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* SHAREHOLDERS */}
    </div>
  );

  function DocumentsModal() {
    return (
      <div className="flex justify-center">
        <div className="fixed top-0 bg-black w-full h-screen opacity-50"></div>
        <div className="absolute bg-white top-12 w-3/4 h-screen p-10 text-center">
          <div className="flex justify-between  mb-2 items-center">
            <h2 className="font-black text-2xl">{documentModal.document}</h2>
            <button
              className="bg-black text-white text-lg px-6 py-2"
              onClick={() =>
                setDocumentModal({
                  name: "",
                  document: "",
                  modal: false,
                })
              }
            >
              Close
            </button>
          </div>
          <object
            data={factory[documentModal.name]}
            type="application/pdf"
            width="100%"
            height="90%"
          >
            <p>
              If pdf doesn't show - clik this link{" "}
              <a href={factory[documentModal.name]}>to the PDF!</a>
            </p>
          </object>
        </div>
      </div>
    );
  }

  function EditDocumentModal() {
    return (
      <div className="flex justify-center">
        <div className="fixed top-0 bg-black w-full h-screen opacity-50"></div>
        <div className="absolute bg-white top-52 m-auto w-[30%] p-10 ">
          <h1 className="text-center text-[20px] font-black">
            Edit Document - {editDocumentModal.document}
          </h1>
          <p className="text-center py-2">
            Edit the document which you have selected and upload the new that
            you want to upload.
          </p>
          <form className="py-3 text-center" enctype="multipart/form-data">
            <input
              type="file"
              name={editDocumentModal.name}
              onChange={(e) => handleDocument(e, editDocumentModal.name)}
              accept="application/pdf"
            />
          </form>
          <div className="space-x-2 mt-4 text-center">
            <button
              className="bg-green-500 text-white text-lg px-6 py-2 "
              onClick={handleOnEditDocument}
            >
              Edit
            </button>
            <button
              className="bg-black text-white text-lg px-6 py-2"
              onClick={() =>
                setEditDocumentModal({ document: "", modal: false })
              }
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }

  function DeleteModal() {
    return (
      <div className="flex justify-center">
        <div className="fixed top-0 bg-black w-full h-screen opacity-50"></div>
        <div className="absolute bg-white top-52 m-auto p-10 text-center">
          <h2 className="text-xl">
            Are you sure you want to delete {factory.name}?
          </h2>
          <p className="pt-2">
            Note: Deleting this factory will result in losing all
            <br /> its data.
          </p>
          <div className="space-x-2 mt-4">
            <button
              className="bg-green-500 text-white text-lg px-6 py-2 "
              onClick={handleDeleteFactory}
            >
              Yes
            </button>
            <button
              className="bg-black text-white text-lg px-6 py-2"
              onClick={() => setModal(false)}
            >
              No
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Details;
