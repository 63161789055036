import Header from "../Partials/Header";
import { useEffect, useState } from "react";
import { getReport } from "../Utils/APIFetching";
import { toast } from "react-hot-toast";
import { NumericFormat } from "react-number-format";
import Loading from "../Components/Loading";
import CategoryDropdown from "../Components/CategoryDropdown";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { MdRemoveRedEye } from "react-icons/md";
import { Link } from "react-router-dom";

function Report() {
  const [params, setParams] = useState({});
  const [report, setReport] = useState({
    licenses: 0,
    stats: {},
    expired: {},
    list: [],
  });
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [factoryModal, setFactoryModal] = useState(false);
  useEffect(() => {
    getReport()
      .then((res) => {
        return res;
      })
      .then((data) => {
        setReport({
          licenses: data.data.licenseTotal,
          list: data.data.stats[0].factories,
          stats: data.data.stats[0],
          expired: data.data.expiredLicenses[0],
        });

        setLoading(false);
      });
  }, []);

  function filter() {
    if (
      (params.from && params.to == undefined) ||
      (params.to && params.from == undefined)
    ) {
      toast.error("Please select a date from and date to");
      return;
    }
    getReport(params)
      .then((res) => {
        return res;
      })
      .then((data) => {
        setReport({
          licenses: data.data.licenseTotal,
          list: data.data.stats.length > 0 ? data.data.stats[0].factories : [],
          stats: data.data.stats[0] || {},
          expired: data.data.expiredLicenses[0],
        });
      });
  }
  if (loading) return <Loading />;
  return (
    <div>
      <Header />
      {modal && <Modal />}
      {factoryModal && <FactoryModal />}
      <div className="px-10 py-5">
        {/* SEARCH BAR */}
        <div className="bg-white my-5 w-[80%] m-auto">
          <div className="flex">
            <input
              type="date"
              className="grow p-3 bg-white border-b border-l border-r"
              onChange={(e) => setParams({ ...params, from: e.target.value })}
            />
            <input
              type="date"
              className="grow p-3 bg-white border-b border-r"
              onChange={(e) => setParams({ ...params, to: e.target.value })}
            />
          </div>

          <div className="grid grid-cols-2 ">
            <select
              type="date"
              className="grow p-3 bg-white border-b border-r border-l"
              onChange={(e) =>
                setParams({ ...params, ownership: e.target.value })
              }
            >
              <option value="">-- Ownership Type --</option>
              <option value="Local Partnership">Local Partnership</option>
              <option value="Foreign Partnership">Foreign Partnership</option>
              <option value="Joint Venture">Joint Venture</option>
              <option value="Local Solo Partnership">
                Local Solo Partnership
              </option>
              <option value="Foreign Solo Partnership">
                Foreign Solo Partnership
              </option>
            </select>
            <select
              type="date"
              className="grow p-3 bg-white border-b border-r "
              onChange={(e) =>
                setParams({ ...params, certification: e.target.value })
              }
            >
              <option value="">-- Certificate Class --</option>
              <option value="a">Class A</option>
              <option value="b">Class B</option>
              <option value="c">Class C</option>
              <option value="s">Class S</option>
            </select>
          </div>
          <div className="border-l border-r">
            <CategoryDropdown
              className="w-full bg-green-300"
              value={params.type}
              set={setParams}
              data={params}
            />
          </div>
          <button
            className="bg-black p-3 w-full text-white mt-4"
            onClick={filter}
          >
            Filter Report
          </button>
        </div>
        {/* SEARCH BAR */}
        <div className="grid grid-cols-2 lg:grid-cols-4 grid-rows-2 gap-4 cursor-pointer">
          <div
            onClick={() => report.list.length > 0 && setFactoryModal(true)}
            className="bg-white col-span-2 lg:col-span-1 row-span-2 py-10 flex flex-col justify-center items-center"
          >
            <h2 className="text-xl">Total Factories</h2>
            <h2 className="text-4xl text-gray-700 py-2">
              <NumericFormat
                value={report.stats.totalFactories || 0}
                displayType={"text"}
                thousandSeparator={true}
              />
            </h2>
          </div>
          <div className="bg-white p-5">
            <h2 className="text-base">Proposed Investment</h2>
            <h2 className="text-xl text-gray-700 font-black">
              <NumericFormat
                value={report.stats.investment || 0}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
              />
            </h2>
          </div>
          <div className="bg-white p-5">
            <h2 className="text-base">Machinery Costs</h2>
            <h2 className="text-xl text-gray-700 font-black">
              <NumericFormat
                value={report.stats.machinery || 0}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
              />
            </h2>
          </div>
          <div className="bg-white p-5">
            <h2 className="text-base">Factory Value</h2>
            <h2 className="text-xl text-gray-700 font-black">
              <NumericFormat
                value={report.stats.factory || 0}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
              />
            </h2>
          </div>
          <div className="bg-white p-5">
            <h2 className="text-base">Factory Land</h2>
            <h2 className="text-xl text-gray-700 font-black">
              <NumericFormat
                value={report.stats.land || 0}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
              />
            </h2>
          </div>
          <div className="col-span-2 bg-gray-100"></div>
          <div className="bg-white row-span-2 py-10 flex flex-col justify-center items-center">
            <h2 className="text-xl">Total Licenses</h2>
            <h2 className="text-4xl text-gray-700 py-2">
              <NumericFormat
                value={report.licenses || 0}
                displayType={"text"}
                thousandSeparator={true}
              />
            </h2>
          </div>
          <div
            className="bg-white row-span-2 py-10 flex flex-col justify-center items-center cursor-pointer"
            onClick={() =>
              report.expired.factories.length > 0 && setModal(true)
            }
          >
            <h2 className="text-xl">Total Expired</h2>
            <h2 className="text-4xl text-gray-700 py-2">
              <NumericFormat
                value={report.expired ? report.expired.total : 0}
                displayType={"text"}
                thousandSeparator={true}
              />
            </h2>
          </div>
        </div>
      </div>
    </div>
  );

  function Modal() {
    return (
      <div className="flex justify-center">
        <div className="fixed top-0 bg-black w-full h-screen opacity-50"></div>

        <div className="absolute w-3/5 bg-white top-16 m-auto p-10 text-center">
          <div className="flex justify-between py-5">
            <h2 className="text-xl">Expiring Companies</h2>
            <button
              className="bg-black text-white text-sm text-lg px-4 py-2"
              onClick={() => setModal(false)}
            >
              Close
            </button>
          </div>

          <TableContainer component={Paper} className="bg-black">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Company Name</TableCell>
                  <TableCell align="right">Contact Name</TableCell>
                  <TableCell align="right">Contact Number</TableCell>
                  <TableCell align="right">View</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {report.expired.factories.map((factory, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {factory.name}
                    </TableCell>
                    <TableCell align="right">{factory.applicant}</TableCell>
                    <TableCell align="right">{factory.applicantTel}</TableCell>
                    <TableCell align="right">
                      <Link
                        to={`/details/${factory.slug}`}
                        className="flex justify-end"
                      >
                        <MdRemoveRedEye />
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    );
  }

  function FactoryModal() {
    return (
      <div className="flex justify-center">
        <div className="fixed top-0 bg-black w-full h-screen opacity-50"></div>

        <div className="absolute w-3/5 bg-white top-16 m-auto p-10 text-center">
          <div className="flex justify-between py-5">
            <h2 className="text-xl">Company list</h2>
            <button
              className="bg-black text-white text-sm text-lg px-4 py-2"
              onClick={() => setFactoryModal(false)}
            >
              Close
            </button>
          </div>

          <TableContainer component={Paper} className="bg-black">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Company Name</TableCell>
                  <TableCell align="right">Sector</TableCell>
                  <TableCell align="right">Ownership</TableCell>
                  <TableCell align="right">Investment</TableCell>
                  <TableCell align="right">Raw origin</TableCell>
                  <TableCell align="right">Factory Location</TableCell>
                  <TableCell align="right">View</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {report.list.map((factory, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {factory.name}
                    </TableCell>
                    <TableCell align="right">{factory.type}</TableCell>
                    <TableCell align="right">{factory.ownership}</TableCell>
                    <TableCell align="right">{factory.investment}</TableCell>
                    <TableCell align="right">{factory.raw}</TableCell>
                    <TableCell align="right">{factory.location}</TableCell>
                    <TableCell align="right">
                      <Link
                        to={`/details/${factory.slug}`}
                        className="flex justify-end"
                      >
                        <MdRemoveRedEye />
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    );
  }
}

export default Report;
