import Header from "../Partials/Header";
import { Link } from "react-router-dom";
function NotFound() {
  return (
    <div>
      <Header />
      <div className="flex justify-center items-center mt-32">
        <div className="text-center">
          <h1 className="text-7xl">404</h1>
          <p className="py-3">
            Page you looking for is not found or your are not authorized to
            view.
          </p>
          <Link to="/dashbaord">
            <button className="bg-black p-3 text-white" type="submit">
              Go Home
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
